<template>
  <div v-if="!token" class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signup-on"
      id="kt_login"
    >
      <div class="side_image">
        <img src="/media/side_image.png" />
      </div>
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <!--begin::Signup-->
        <div class="login-form login-signup">
          <div class="logo text-center mb_85 pt-lg-0">
            <img
              v-if="base_URL.includes('fehm')"
              style="width: 86%"
              src="/media/fehm_logo.png"
            />
                   <img v-else-if="base_URL.includes('mcit')"  alt="Logo" src="/media/logo.png" />
                <img v-else  alt="Logo" src="/media/Logos/manash.png" />
          </div>
          <div v-if="error_mesage.length" class="error_mesage">
            <h3>برجاء ادخال البيانات التاليه :</h3>
            <ul>
              <li v-for="err in error_mesage" :key="err">
                {{ err }}
              </li>
            </ul>
          </div>
          <div v-if="API_ERROR == 'pass_same'" class="error_mesage">
            <p>كلمة المرور وتأكيد كلمة المرور غير متشابهان</p>
          </div>
          <div v-if="API_ERROR == 'email_taken'" class="error_mesage">
            <p>هذا البريد موجود بالفعل بالرجاء إختيار بريد إلكترونى اخر</p>
          </div>
          <div v-if="API_ERROR == 'mobile_taken'" class="error_mesage">
            <p>هذا الرقم موجود بالفعل بالرجاء إختيار رقم هاتف اخر</p>
          </div>
          <div v-if="API_ERROR == 'mobile_notvalid'" class="error_mesage">
            <p>رقم الهاتف غير صالح</p>
          </div>
          <div v-if="API_ERROR == 'email_notvalid'" class="error_mesage">
            <p>بريد إلكترونى غير صالح</p>
          </div>
          <div v-if="API_ERROR == 'code_notvalid'" class="error_mesage">
            <p>كود تأكيد غير صاح</p>
          </div>
          <div v-if="sucss_mesage == 'mobile_updated'" class="sucsess_mesage">
            <p>تم تعديل رقم الهاتف بنجاح وإرسال رمز التأكيد الى رقمك الجديد</p>
          </div>
          <div v-if="sucss_mesage == 'code_sent'" class="sucsess_mesage">
            <p>تم إعادة إرسال الكود الى هاتفك المحمول</p>
          </div>
          <div v-if="sucss_mesage == 'mesage_sent'" class="sucsess_mesage">
            <p>تم إعادة إرسال رسالة التأكيد الى بريدك الإلكترونى</p>
          </div>

          <div v-if="sucss_mesage == 'email_updated'" class="sucsess_mesage">
            <p>
              تم تعديل البريد الإلكترونى بنجاح وإرسال رسالة التأكيد الى بريدك
              الجديد
            </p>
          </div>

          <form
            v-if="Step == 1"
            class="form"
            novalidate="novalidate"
            id="kt_login_signin_form"
          >
            <div class="form-group">
              <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                  <i class="far fa-user"></i>
                </b-input-group-prepend>
                <b-form-input
                  :class="[error_mesage.includes('الإسم') && 'err_input']"
                  v-model="form.name"
                  type="text"
                  placeholder="الإسم"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="form-group">
              <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                  <i class="far fa-envelope"></i>
                </b-input-group-prepend>
                <b-form-input
                  :class="[
                    error_mesage.includes('البريد الإلكترونى') && 'err_input',
                  ]"
                  v-model="form.email"
                  type="email"
                  placeholder="البريد الإلكترونى"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="form-group">
              <vue-tel-input
                :class="[error_mesage.includes('رقم الموبايل') && 'err_input']"
                v-model="mobile"
                id="phonenumber"
                @country-changed="handlePhoneCountry"
                inputOptions.placeholder="رقم الموبايل"
              ></vue-tel-input>
            </div>
            <div class="form-group">
              <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                  <i class="fas fa-lock"></i>
                </b-input-group-prepend>
                <b-form-input
                  :class="[error_mesage.includes('كلمة المرور') && 'err_input']"
                  v-model="form.password"
                  type="password"
                  placeholder="كلمة المرور"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="form-group">
              <b-input-group class="mb-2">
                <b-input-group-prepend is-text>
                  <i class="fas fa-lock"></i>
                </b-input-group-prepend>
                <b-form-input
                  :class="[
                    error_mesage.includes('تأكيد كلمة المرور') && 'err_input',
                  ]"
                  v-model="form.c_password"
                  type="password"
                  placeholder="تأكيد كلمة المرور"
                ></b-form-input>
              </b-input-group>
            </div>

            <div class="form-group">
              <v-btn
                :loading="isLoading ? true : false"
                class="btn btn_brimary font-weight-bolder font-size-h6 px-15 py-4 my-3"
                @click="onSubmitStep1()"
              >
                التالى
              </v-btn>
            </div>
            <div class="form-group">
              <span class="text-muted font-weight-bold font-size-h4">
                لديك حساب بالفعل ؟
                <a
                  id="kt_login_signup"
                  class="cursor_pointer text-primary font-weight-bolder"
                  @click="$router.push(`/login/${$route.params.groupslug}`)"
                >
                  قم بتسجيل الدخول
                </a></span
              >
            </div>
          </form>
          <form
            v-if="Step == 2"
            class="form"
            novalidate="novalidate"
            id="kt_login_signin_form"
          >
            <p class="message_info">
              برجاء إدخال رمز التحقق المكون من 4 أرقام الذي <br />
              أرسلناه عبر الرسائل القصيرة
            </p>
            <div class="form-group d-flex justify-content-center">
              <!-- Update_mobile -->
              <div
                v-if="edit_num"
                class="d-block w-100"
                style="text-align: end"
              >
                <vue-tel-input
                  v-model="new_mobile"
                  @country-changed="handlePhoneCountry"
                ></vue-tel-input>
                <button type="button" @click="Update_mobile" class="btn_Green btn_main">
                  تعديل الهاتف
                </button>
              </div>
              <h3 v-if="!edit_num" class="phone_number">{{ form.mobile }}</h3>
              <button
                v-if="!edit_num"
                class="edit_btn"
                @click="edit_num = true"
              >
                <img style="width: 20px" src="/media/icons/edit.svg" />
              </button>
            </div>
            <div class="form-group">
              <CodeInput
                v-on:change="Saveverfication_code"
                :loading="false"
                class="input"
                :fields="4"
              />
            </div>

            <div class="form-group d-flex justify-content-center">
              <!--   -->
              <vue-countdown
                v-if="enable_btn == false"
                :time="60000"
                v-slot="{ seconds }"
                class="direction_initial"
              >
                <h3 class="sec_lable">
                  {{ seconds }}
                  ثانية
                </h3>
              </vue-countdown>
              <vue-countdown
                v-else
                :time="0"
                v-slot="{ seconds }"
                class="direction_initial"
              >
                <h3 class="sec_lable">
                  {{ seconds }}
                  ثانية
                </h3>
              </vue-countdown>
            </div>
            <div class="form-group d-flex justify-content-center">
              <h3 class="send_code">
                لم يصلك الكود؟

                <button
                  type="button"
                  v-if="enable_btn == false"
                  title="يمكنك اعاده ارسال الرمز بعد مرور 60 ثانية"
                  class="disabled_btn"
                >
                  إرسال الرمز مجددا
                </button>
                <v-btn
                  class="Send_code_btn"
                  v-else
                  type="button"
                  title="إرسال الرمز مرة اخرى"
                  @click="replay_sms_mobile()"
                  :loading="isLoadingSend ? true : false"
                >
                  إرسال الرمز مجددا
                </v-btn>
              </h3>
            </div>

            <div class="form-group">
              <v-btn
                :loading="isLoading ? true : false"
                class="btn btn_brimary font-weight-bolder font-size-h6 px-15 py-4 my-3"
                type="button"
                @click="ConfirmCode()"
              >
                تأكيد الكود
              </v-btn>
              <div class="form-group">
                <span class="text-muted font-weight-bold font-size-h4">
                  لديك حساب بالفعل ؟
                  <a
                    id="kt_login_signup"
                    class="cursor_pointer text-primary font-weight-bolder"
                    @click="$router.push('/login')"
                  >
                    قم بتسجيل الدخول
                  </a></span
                >
              </div>
            </div>
          </form>
          <form
            v-if="Step == 3"
            class="form"
            novalidate="novalidate"
            id="kt_login_signin_form"
          >
            <p class="message_info">
              من فضلك قم بتأكيد البريد الإلكتروني عن طريق الرسالة <br />
              المرسلة من موقعنا على بريدك الموضح في الاسفل
            </p>
            <div class="form-group d-flex justify-content-center">
              <div
                v-if="edit_email"
                class="d-block w-100"
                style="text-align: end"
              >
                <b-input-group class="mb-2">
                  <b-input-group-prepend is-text>
                    <i class="far fa-envelope"></i>
                  </b-input-group-prepend>
                  <b-form-input v-model="new_email" type="email"></b-form-input>
                </b-input-group>
                <button type="button" @click="Update_email" class="btn_Green btn_main">
                  تعديل البريد الإلكتروني
                </button>
              </div>

              <h3 v-if="!edit_email" class="phone_number">{{ form.email }}</h3>
              <button
                v-if="!edit_email"
                class="edit_btn"
                @click="edit_email = true"
              >
                <img style="width: 20px" src="/media/icons/edit.svg" />
              </button>
            </div>

            <div class="form-group d-flex justify-content-center">
              <h3 class="send_code">
                إذا لم تستلم رسالة التأكيد بعد برجاء طلب إرسالها مره اخري
              </h3>
            </div>

            <div class="form-group">
              <v-btn
                class="btn btn_brimary font-weight-bolder font-size-h6 px-15 py-4 my-3"
                type="button"
                title="إرسال الرمز مرة اخرى"
                @click="replay_email()"
                :loading="isLoading ? true : false"
              >
                إرسال الرسالة مره آخري
              </v-btn>
              <div class="form-group">
                <span class="text-muted font-weight-bold font-size-h4">
                  لديك حساب بالفعل ؟
                  <a
                    id="kt_login_signup"
                    class="cursor_pointer text-primary font-weight-bolder"
                    @click="$router.push('/login')"
                  >
                    قم بتسجيل الدخول
                  </a></span
                >
              </div>
            </div>
          </form>
        </div>
        <!--end::Signup-->

        <!--begin::Content footer-->
        <div
          class="login_footer d-flex text-center justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          جميع الحقوق محفوظه لدى وزارة الاتصالات وتقنية المعلومات 2021
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center flex-row-auto"
        :style="{ backgroundImage: `url(/media/Path1.svg` }"
      >
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat position-relative bgi-size-cover bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(/media/tower.png)` }"
        >
          <div class="slogin_container">
            <h3>
              تمكين المملكة العربية السعودية , اقتصاديا<br />
              ومجتمعا ووطنا, من استثمار الفرص التى يوفرها<br />
              عنصر الرقمنه والوصول بالمملكة لطليعة الدول<br />
              المبتكرة
            </h3>
          </div>
          <div class="green_path">
            <img src="/media/Path2.svg" />
          </div>
        </div>
      </div>
      <!--begin::Aside-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import CodeInput from "vue-verification-code-input";
import VueCountdown from "@chenfengyuan/vue-countdown";
// FormValidation plugins
// import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
// import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
// import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import auth from "../../../../http/requests/auth";
export default {
  name: "login-1",
  data() {
    return {
      isLoading: false,
      isLoadingSend: false,
      enable_btn: false,
      fileRecords: [],
      edit_num: false,
      edit_email: false,
      Step: 1,
      state: "signin",
      form: {},
      country_code: "",
      mobile: "",
      API_ERROR: "",
      error_mesage: [],
      sucss_mesage: "",
      verfication_code: null,
      new_mobile: "",
      new_email: "",
      token: null,
      user_id: null,
      base_URL: "",
    };
  },
  components: { CodeInput, VueCountdown },

  methods: {
    handleCountdownProgress(val) {
      if (val.seconds == 0) {
        this.seconds = 0;
      }
    },
    handlePhoneCountry(info) {
      this.country_code = info.dialCode;
    },
    onSubmitStep1() {
      this.isLoading = true;
      (this.API_ERROR = ""), (this.sucss_mesage = "");
      this.error_mesage = [];
      if (!this.form.name) {
        this.error_mesage.push("الإسم");
        this.isLoading = false;
      }
      if (!this.form.email) {
        this.error_mesage.push("البريد الإلكترونى");
        this.isLoading = false;
      }
      if (!this.mobile) {
        this.error_mesage.push("رقم الموبايل");
        this.isLoading = false;
      }
      if (!this.form.password) {
        this.error_mesage.push("كلمة المرور");
        this.isLoading = false;
      }
      if (!this.form.c_password) {
        this.error_mesage.push("تأكيد كلمة المرور");
        this.isLoading = false;
      }
      if (
        (this.form.password || this.form.c_password) &&
        this.form.password != this.form.c_password
      ) {
        this.API_ERROR = "pass_same";
        this.isLoading = false;
      }
      if (
        this.form.name &&
        this.form.email &&
        this.mobile &&
        this.form.password &&
        this.form.c_password &&
        this.form.password === this.form.c_password
      ) {
        let mobile = this.mobile;
        this.form.mobile = mobile.replace(/\s/g, "");
        this.form.country_code = this.country_code;
        this.form.slug = this.$route.params.groupslug;
        this.form.base_url = `${window.location.origin}/#`;

        auth
          .Register(this.form)
          .then((res) => {
            this.isLoading = false;
            this.new_mobile = this.mobile;
            this.user_id = res.data.data.id;
            this.Step = 2;
          })
          .catch((err) => {
            this.isLoading = false;
            if (
              err.response.data.error == "The email has already been taken."
            ) {
              this.API_ERROR = "email_taken";
            } else if (
              err.response.data.error == "The mobile has already been taken."
            ) {
              this.API_ERROR = "mobile_taken";
            } else if (
              err.response.data.error == "The mobile must be a number."
            ) {
              this.API_ERROR = "mobile_notvalid";
            } else if (
              err.response.data.error ==
              "The email must be a valid email address."
            ) {
              this.API_ERROR = "email_notvalid";
            } else {
              if (err.response.data.error) {
                this.$vs.notify({
                  text: err.response.data.error,
                  color: "danger",
                });
              } else {
                this.$vs.notify({
                  text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                  color: "danger",
                });
              }
            }
          });
      }
    },
    Saveverfication_code(value) {
      this.verfication_code = value;
    },
    ConfirmCode() {
      (this.API_ERROR = ""), (this.sucss_mesage = "");
      this.error_mesage = [];
      this.isLoading = true;
      const form_verfiy = {
        verfication_code: this.verfication_code,
        mobile: this.form.mobile,
      };
      auth
        .verfiy_mobile(form_verfiy)
        .then((res) => {
          this.Step = 3;
          this.new_email = this.form.email;
          this.isLoading = false;
        })
        .catch((err) => {
          if (
            err.response.data.error == "The new email has already been taken."
          ) {
            this.API_ERROR = "email_taken";
          } else {
            this.API_ERROR = "code_notvalid";
          }

          this.isLoading = false;
        });
    },
    replay_sms_mobile() {
      (this.API_ERROR = ""), (this.sucss_mesage = "");
      this.error_mesage = [];
      this.enable_btn = false;
      this.isLoadingSend = true;
      const form = {
        mobile: this.form.mobile,
      };
      auth
        .replay_sms_mobile(form)
        .then((res) => {
          this.sucss_mesage = "code_sent";
          this.isLoadingSend = false;
        })
        .catch((err) => {
          this.isLoadingSend = false;
        });
    },
    replay_email() {
      (this.API_ERROR = ""), (this.sucss_mesage = "");
      this.error_mesage = [];
      this.enable_btn = false;
      this.isLoading = true;
      const form = {
        email: this.form.email,
        base_url: `${window.location.origin}/#`,
      };
      auth
        .replay_email(form)
        .then((res) => {
          this.sucss_mesage = "mesage_sent";
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    Update_mobile() {
      (this.API_ERROR = ""), (this.sucss_mesage = "");
      this.error_mesage = [];
      let mobile = this.new_mobile;
      let new_mobile = mobile.replace(/\s/g, "");
      const form_Update = {
        id: this.user_id,
        new_mobile: new_mobile,
        country_code: this.country_code,
      };
      auth
        .Update_mobile(form_Update)
        .then((res) => {
          this.sucss_mesage = "mobile_updated";
          this.form.mobile = new_mobile;
          this.edit_num = false;
        })
        .catch((err) => {
          this.API_ERROR = "mobile_notvalid";
        });
    },
    Update_email() {
      (this.API_ERROR = ""), (this.sucss_mesage = "");
      this.error_mesage = [];
      const form_Update = {
        id: this.user_id,
        new_email: this.new_email,
        base_url: `${window.location.origin}/#`,
      };
      auth
        .Update_email(form_Update)
        .then((res) => {
          this.sucss_mesage = "email_updated";
          this.form.email = this.new_email;
          this.edit_email = false;
        })
        .catch((err) => {
          this.API_ERROR = "email_notvalid";
        });
    },
  },
  created() {
    this.base_URL = window.location.origin;
    this.token = localStorage.getItem("id_token");
    if (this.token) {
      window.location.href = "/";
    } else {
      if (this.$route.params.moblie_number) {
        (this.form.mobile = this.$route.params.moblie_number), (this.Step = 2);
      }
      if (this.$route.params.email && !this.$route.params.moblie_number) {
        (this.form.email = this.$route.params.email), (this.Step = 3);
      }
      setInterval(() => {
        this.enable_btn = true;
      }, 60000);
    }
  },
};
</script>
<style scoped lang="scss">
.react-code-input-container {
  border: none !important;
  box-shadow: none;
  justify-content: center;
}
.btn_brimary {
  margin-bottom: 20px;
}
.btn_Green {
  padding: 8px 20px;
  margin-top: 10px;
}
.form-control {
  background: transparent !important;
}
.vue-tel-input {
  font-size: 17px;
  height: 45px !important;
  border: 1px solid #e5e5e5 !important;
  padding-right: 54px !important;
  .vti__input {
    font-size: 17px !important;
    color: #b5b5c3 !important ;
    ::placeholder {
      color: #b5b5c3 !important;
      font-size: 17px !important;
    }
  }
  ::placeholder {
    color: #b5b5c3 !important;
    font-size: 17px !important;
  }
}
.Send_code_btn {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}
.v-btn {
  font-size: 15px !important;
  font-weight: bold !important;
}
.sec_lable {
  direction: rtl;
}
</style>